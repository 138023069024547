import React, { FormEvent, useEffect, useState } from 'react';
import { navigate, PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import { useUser } from '@/hooks/useUser';
import { useIntl } from 'react-intl';
import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { Button, Input } from '@mikafi/ui';
import classNames from 'classnames';

const Login = ({}: PageProps) => {
  const supabaseClient = useSupabaseClient();
  const { user, isLoading } = useUser();
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{ type?: string; content?: string }>({
    type: '',
    content: '',
  });

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setMessage({});

    const { error } = await supabaseClient.auth.signInWithOtp({
      email,
      options: {
        emailRedirectTo: `${window?.location?.origin || ''}/${
          intl.locale
        }/account/`,
      },
    });

    if (error) {
      setMessage({ type: 'error', content: error.message });
    }

    setMessage({
      type: 'note',
      content: intl.formatMessage({ id: 'emailLink' }),
    });

    setLoading(false);
  };

  useEffect(() => {
    if (user) {
      navigate(`/${intl.locale}/account/`);
    }
  }, [user]);

  if (isLoading) return null;

  return (
    <Layout
      alternatePages={[
        { locale: 'de', value: 'login' },
        { locale: 'en', value: 'login' },
      ]}
    >
      <Seo title="Login" />

      <div className="bg-white -mt-10">
        <div className="py-36 px-4 min-h-[90vh] flex items-center">
          <div className="max-w-sm mx-auto">
            <h1 className="mb-8">{intl.formatMessage({ id: 'login' })}</h1>

            <p>{intl.formatMessage({ id: 'yourBenefitsLogin' })}</p>

            {message.content && (
              <div
                className={classNames('p-6 rounded-4xl mb-8', {
                  'bg-red-400': message.type === 'error',
                  'bg-green-400': message.type !== 'error',
                })}
              >
                {message.content}
              </div>
            )}

            <form onSubmit={handleLogin}>
              <div className="mb-4">
                <Input
                  className="w-full"
                  required
                  type="email"
                  placeholder={intl.formatMessage({ id: 'email' })}
                  onChange={e => setEmail(e.target.value)}
                  name="email"
                />
              </div>

              <Button full type="submit" disabled={loading}>
                {intl.formatMessage({ id: 'signIn' })}
              </Button>
            </form>

            <div className="relative text-center after:border-b after:border-gray-500 after:absolute after:w-full after:inset-x-0 after:top-3 my-8">
              <span className="bg-white z-10 relative px-4 inline-block">
                {intl.formatMessage({ id: 'dontHaveAnAccount' })}
              </span>
            </div>

            <Button
              onClick={() => navigate(`/${intl.locale}/register/`)}
              className="w-full"
              variant="secondary"
            >
              {intl.formatMessage({ id: 'register' })}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
